.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh; // Make sure the App fills the viewport height
    background-color: #121212;
}

.content {
    flex: 1 0 auto; // Makes content flexible but pushes footer down
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px; // Add padding for breathing room around content
    overflow: auto; // Ensures scroll if content overflows  
    color: #fff; // White text for better contrast in dark mode


    .p-fileupload {
        width: 80%;
        height: 400px;
        overflow: auto; // Add scrolling if content overflows
    }

    .p-datatable-striped {
        .p-button.p-button-success {
            color: #ffffff;
            background: #121212;
            border: 3px solid #333333;

        }
    }


    .p-datatable .p-datatable-tbody>tr>td {
        border-bottom: 1px solid #333; // Darker borders for rows

        .url-text {
            color: #282828;
            text-decoration: none;
            font-size: large;
        }
    }

}

.app-footer {
    padding: 20px;
    background-color: #333;
    color: white;
    text-align: center;
    width: 100%;
    height: 60px; // Fixed height for footer
    position: relative; // Change to relative for natural flow, remove if sticky footer is needed
    margin-top: auto; // Pushes to the bottom if there is space

    a {
        color: white;
        text-decoration: none;
    }
}


.p-menubar {
    background-color: #333; // Dark background for menubar
    border: none; // Remove border

    .p-menuitem-link {
        color: #fff; // White text color for menu items

        &:hover {
            background-color: #666; // Darker background on hover
        }
    }
}